<template>
  <div>
    <h1>Relação de Praças</h1>

    <v-btn color="error" title="Voltar" @click="$router.back()" class="ma-2">
      <v-icon>mdi-keyboard-return</v-icon>
    </v-btn>

    <v-row>
      <v-col cols="12" md="6">
        <v-select
          label="Parceira"
          :items="partners"
          itemText="name"
          itemValue="id"
          v-model="partner"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-btn
          @click="generateReport"
          color="success"
          title="Gerar"
          class="ma-2"
          :disabled="!partner"
        >
          <v-icon>mdi-open-in-new</v-icon>
          Abrir
        </v-btn>
        <v-btn
          @click="saveReport"
          color="primary"
          title="Gerar"
          class="ma-2"
          :disabled="!partner"
        >
          <v-icon>mdi-download</v-icon>
          Baixar
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { jsPDF } from "jspdf";
import "jspdf-autotable";

import logo from "../../../assets/logo_sem_fundo.png";
export default {
  mounted() {
    this.getPartners();
    this.getCities();
  },
  data() {
    return {
      partners: [],
      cities: [],
      partner: null,
    };
  },
  methods: {
    handlePartnerFee(city) {
      let partnerFee = 0;
      city.city_fees.forEach((fee) => {
        if (fee.partner_id === this.partner) {
          partnerFee = fee.value;
        }
      });

      return this.$functions.moneyFormat(partnerFee);
    },
    async getPartners() {
      try {
        const { data } = await this.$http.get("api/partners");
        this.partners = data;
      } catch (error) {
        this.$toast.error("Erro ao buscar parceiros");
      }
    },
    async getCities() {
      try {
        const { data } = await this.$http.get("api/cities");
        this.cities = data;
      } catch (error) {
        this.$toast.error("Erro ao buscar cidades");
      }
    },
    generateReport() {
      let doc = new jsPDF({ orientation: "portrait" });
      const data = [];
      this.cities.map((city) => {
        if (
          city.monday ||
          city.tuesday ||
          city.wednesday ||
          city.thursday ||
          city.friday
        ) {
          data.push([
            city.name,
            city.monday ? "x" : "",
            city.tuesday ? "x" : "",
            city.wednesday ? "x" : "",
            city.thursday ? "x" : "",
            city.friday ? "x" : "",
            this.handlePartnerFee(city),
          ]);
        }
      });

      doc.text(
        "Relação de praças | Latina Transportes",
        75,
        20,
        null,
        null,
        "center"
      );

      doc.addImage(logo, "JPEG", 125, -5, 50, 50);

      doc.autoTable({
        head: [["Município", "Seg", "Ter", "Qua", "Qui", "Sex", "TDA"]],
        body: [...data],
        margin: { top: 30 },
        theme: "grid",
        columnStyles: {
          1: { halign: "center" },
          2: { halign: "center" },
          3: { halign: "center" },
          4: { halign: "center" },
          5: { halign: "center" },
        },
      });

      let finalY = doc.lastAutoTable.finalY;
      doc.setFontSize(12);
      doc.text(
        105,
        finalY + 10,
        "OBS: Frequência de atendimento pode sofrer alterações conforme o fluxo de carga.",
        null,
        null,
        "center"
      );

      let string = doc.output("datauristring");
      let embed = "<embed width='100%' height='100%' src='" + string + "'/>";
      let win = window.open();
      win.document.open();
      win.document.write(embed);
      win.document.write(`
        <style>
          * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }
        </style>
      `);
      win.document.close();
    },

    saveReport() {
      let doc = new jsPDF({ orientation: "portrait" });
      const data = [];
      this.cities.map((city) => {
        if (
          city.monday ||
          city.tuesday ||
          city.wednesday ||
          city.thursday ||
          city.friday
        ) {
          data.push([
            city.name,
            city.monday ? "x" : "",
            city.tuesday ? "x" : "",
            city.wednesday ? "x" : "",
            city.thursday ? "x" : "",
            city.friday ? "x" : "",
            this.handlePartnerFee(city),
          ]);
        }
      });

      doc.text(
        "Relação de praças | Latina Transportes",
        75,
        20,
        null,
        null,
        "center"
      );

      doc.addImage(logo, "JPEG", 125, -5, 50, 50);

      doc.autoTable({
        head: [["Município", "Seg", "Ter", "Qua", "Qui", "Sex", "TDA"]],
        body: [...data],
        margin: { top: 30 },
        theme: "grid",
        columnStyles: {
          1: { halign: "center" },
          2: { halign: "center" },
          3: { halign: "center" },
          4: { halign: "center" },
          5: { halign: "center" },
        },
      });

      let finalY = doc.lastAutoTable.finalY;
      doc.setFontSize(12);
      doc.text(
        105,
        finalY + 10,
        "OBS: Frequência de atendimento pode sofrer alterações conforme o fluxo de carga.",
        null,
        null,
        "center"
      );

      doc.save("relacao-de-pracas");
    },
  },
};
</script>
